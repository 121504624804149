import { AppThunk, RootState } from './store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  selectedStudents,
  updateSelectedStudents,
} from './quizCheatCheckingSlice';
import { useAppDispatch, useAppSelector } from './hooks';

import { SelectOption } from '../components/Selector';

export interface NavbarQuizCheatCheckingState {
  // Scope
  scopeMetaDataStatus: boolean;
  scopeMetaDataError: string;
  scopeMetaData: ScopeMetaData[];
  navbarScopes: SelectOption[];
  navbarActiveScope: SelectOption;

  // Quiz
  quizMetaDataStatus: boolean;
  quizMetaDataError: string;
  quizMetaData: QuizMetaData[];
  navbarQuizzes: SelectOption[];
  navbarActiveQuiz: SelectOption;

  // Case Type
  caseTypeMetaDataStatus: boolean;
  caseTypeMetaDataError: string;
  caseTypeMetaData: CaseTypeMetaData[];
  navbarCaseTypes: SelectOption[];
  navbarActiveCaseType: SelectOption;

  // Case Filter
  caseFilterMetaDataStatus: boolean;
  caseFilterMetaDataError: string;
  caseFilterMetaData: CaseFilterMetaData[];
  navbarCaseFilters: SelectOption[];
  navbarActiveCaseFilter: SelectOption;
  topSuspiciousFilterSetting: Record<string, unknown>;

  // Case Order
  caseOrderMetaDataStatus: boolean;
  caseOrderMetaDataError: string;
  caseOrderMetaData: CaseOrderMetaData[];
  navbarCaseOrders: SelectOption[];
  navbarActiveCaseOrder: SelectOption;

  // Case
  caseMetaDataStatus: boolean;
  caseMetaDataError: string;
  caseMetaData: CaseMetaData[];
  filteredCases: CaseMetaData[];
  activeCaseMetaData: CaseMetaData;
  activeCaseMetaDataIndex: number;
  navbarCases: SelectOption[];
  navbarActiveCase: SelectOption;
  isCaseFixed: boolean;
  caseCheckStatusMap: Record<string, unknown>;
}

interface ScopeMetaData {
  label: string;
  value: string;
}

interface CaseTypeMetaData {
  label: string;
  value: string;
}

interface CaseFilterMetaData {
  label: string;
  value: string;
}

interface CaseOrderMetaData {
  label: string;
  value: string;
}

export interface QuizMetaData {
  slug: string;
  name: string;
  startTime: string;
  endTime: string;
  scores: string;
}

interface CaseMetaData {
  studentInfo: CaseMetaDataStudentInfo[];
  caseInfo: any;
}

export interface CaseMetaDataStudentInfo {
  id: string;
}

const initialState: NavbarQuizCheatCheckingState = {
  // Scope
  scopeMetaDataStatus: false,
  scopeMetaDataError: '',
  scopeMetaData: [],
  navbarScopes: [],
  navbarActiveScope: {
    value: '',
    label: '',
  },
  // Quiz
  quizMetaDataStatus: false,
  quizMetaDataError: '',
  quizMetaData: [],
  navbarQuizzes: [],
  navbarActiveQuiz: {
    value: '',
    label: '',
  },
  // Case Type
  caseTypeMetaDataStatus: false,
  caseTypeMetaDataError: '',
  caseTypeMetaData: [],
  navbarCaseTypes: [],
  navbarActiveCaseType: {
    label: '',
    value: '',
  },
  // Case Filter
  caseFilterMetaDataStatus: false,
  caseFilterMetaDataError: '',
  caseFilterMetaData: [],
  navbarCaseFilters: [],
  navbarActiveCaseFilter: {
    label: '',
    value: '',
  },
  topSuspiciousFilterSetting: {
    numberOfOverlapPages: 10,
    numberOfSyncPages: 10,
    overlapPercentage: 10,
    overlapSeconds: 10,
  },
  // Case Order
  caseOrderMetaDataStatus: false,
  caseOrderMetaDataError: '',
  caseOrderMetaData: [],
  navbarCaseOrders: [],
  navbarActiveCaseOrder: {
    label: '',
    value: '',
  },
  // Case
  caseMetaDataStatus: false,
  caseMetaDataError: '',
  caseMetaData: [],
  activeCaseMetaData: {
    studentInfo: [],
    caseInfo: {},
  },
  activeCaseMetaDataIndex: -1,
  navbarActiveCase: {
    value: '',
    label: '',
  },
  navbarCases: [],
  filteredCases: [],
  isCaseFixed: false,
  caseCheckStatusMap: {},
};

const { REACT_APP_BACKEND_URI } = process.env;

export const navbarQuizCheatCheckingSlice = createSlice({
  name: 'navbarQuizCheatChecking',
  initialState,
  reducers: {
    updateActiveQuiz: (state, action: PayloadAction<any>) => {
      state.navbarActiveQuiz = action.payload;

      // Update URL with quiz value
      const splitURL = window.location.pathname.split('/');
      // Assuming quiz is the 3rd parameter in the URL, adjust index if needed
      splitURL[4] = action.payload.value;
      window.history.pushState(
        { quiz: 'Update quiz' },
        'Dashboard',
        splitURL.join('/'),
      );
    },
    updateQuizMetaData: (state, action: PayloadAction<any>) => {
      const quizzes: QuizMetaData[] = [];
      const navbarQuizzes: SelectOption[] = [];
      action.payload.forEach((item: any) => {
        quizzes.push({
          slug: item.module_slug,
          name: item.module_name,
          startTime: item.module_date_to_activate,
          endTime: item.module_date_to_submit,
          scores: item.module_max_score,
        });

        navbarQuizzes.push({
          value: item.module_slug,
          label: item.module_name,
        });
      });

      // Sort by startTime
      quizzes.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
      );

      // Sort navbarQuizzes to match the quizzes order
      const sortedNavbarQuizzes = quizzes.map((quiz) => ({
        value: quiz.slug,
        label: quiz.name,
      }));

      state.quizMetaData = quizzes;
      state.navbarQuizzes = sortedNavbarQuizzes;

      // Set default quiz only if none is selected
      if (
        state.navbarActiveQuiz.value === '' &&
        sortedNavbarQuizzes.length > 0
      ) {
        state.navbarActiveQuiz = sortedNavbarQuizzes[0];
      }

      state.quizMetaDataStatus = true;
      state.quizMetaDataError = '';
    },
    updateQuizMetaDataError: (state, action: PayloadAction<any>) => {
      state.quizMetaData = initialState.quizMetaData;
      state.navbarQuizzes = initialState.navbarQuizzes;
      state.navbarActiveQuiz = initialState.navbarActiveQuiz;
      state.quizMetaDataStatus = false;
      state.quizMetaDataError = action.payload;
    },
    cleanQuizMetaData: (state) => {
      state.quizMetaData = initialState.quizMetaData;
      state.navbarQuizzes = initialState.navbarQuizzes;
      state.navbarActiveQuiz = initialState.navbarActiveQuiz;
      state.quizMetaDataStatus = initialState.quizMetaDataStatus;
      state.quizMetaDataError = initialState.quizMetaDataError;
    },
    // TODO: Select & Button logic need to be improved, also keep track of navbarActiveCaseIndex,
    //  so we can update navbar options quickly, instead copying and updating the whole list
    updateActiveCaseBySelect: (state, action: PayloadAction<any>) => {
      state.navbarActiveCase = action.payload;
      let filteredCaseIndex = 0;
      const navbarCaseIndex = state.navbarCases.findIndex(
        (navbarCase: SelectOption) => navbarCase.value === action.payload.value,
      );
      switch (state.navbarActiveCaseType.value) {
        case 'pageview': {
          const studentIds = action.payload.value.split(':');
          filteredCaseIndex = state.filteredCases.findIndex(
            (caseMetaData: CaseMetaData) =>
              caseMetaData.studentInfo[0].id === studentIds[0] &&
              caseMetaData.studentInfo[1].id === studentIds[1],
          );
          break;
        }

        case 'time_outlier': {
          const studentId = action.payload.value;
          filteredCaseIndex = state.filteredCases.findIndex(
            (caseMetaData: CaseMetaData) =>
              caseMetaData.studentInfo[0].id === studentId,
          );
          break;
        }
      }
      state.activeCaseMetaData = state.filteredCases[filteredCaseIndex];

      state.activeCaseMetaDataIndex = navbarCaseIndex;
    },
    updateActiveCaseByButton: (state, action) => {
      let hasChange = false;
      if (action.payload) {
        if (state.activeCaseMetaDataIndex < state.filteredCases.length - 1) {
          state.activeCaseMetaDataIndex = state.activeCaseMetaDataIndex + 1;
          hasChange = true;
        }
      } else {
        if (state.activeCaseMetaDataIndex > 0) {
          state.activeCaseMetaDataIndex = state.activeCaseMetaDataIndex - 1;
          hasChange = true;
        }
      }
      if (hasChange) {
        let filteredCaseIndex = 0;
        const activeNavbarCase =
          state.navbarCases[state.activeCaseMetaDataIndex];
        switch (state.navbarActiveCaseType.value) {
          case 'pageview': {
            const studentIds = activeNavbarCase.value.split(':');
            filteredCaseIndex = state.filteredCases.findIndex(
              (caseMetaData: CaseMetaData) =>
                caseMetaData.studentInfo[0].id === studentIds[0] &&
                caseMetaData.studentInfo[1].id === studentIds[1],
            );
            break;
          }

          case 'time_outlier': {
            const studentId = activeNavbarCase.value;
            filteredCaseIndex = state.filteredCases.findIndex(
              (caseMetaData: CaseMetaData) =>
                caseMetaData.studentInfo[0].id === studentId,
            );
            break;
          }
        }
        state.activeCaseMetaData = state.filteredCases[filteredCaseIndex];

        let navbarMatchValue = '';
        switch (state.navbarActiveCaseType.value) {
          case 'pageview': {
            navbarMatchValue =
              state.activeCaseMetaData.studentInfo[0].id +
              ':' +
              state.activeCaseMetaData.studentInfo[1].id;
            break;
          }

          case 'time_outlier': {
            navbarMatchValue = state.activeCaseMetaData.studentInfo[0].id;
            break;
          }
        }
        state.navbarActiveCase = {
          value: navbarMatchValue,
          label:
            state.activeCaseMetaData.caseInfo[
              state.navbarActiveCaseOrder.value
            ] +
            '|' +
            state.caseCheckStatusMap[navbarMatchValue] +
            '|' +
            navbarMatchValue,
        };
      }
    },
    updateIsCaseFixed: (state, action: PayloadAction<any>) => {
      state.isCaseFixed = action.payload;
    },
    updateCaseMetaData: (state, action: PayloadAction<any>) => {
      state.navbarCases = [];
      const caseMetaData: CaseMetaData[] = [];
      const caseCheckStatusMap = {};
      const checkStatusMap = { ...action.payload.checkStatusMap };

      switch (state.navbarActiveCaseType.value) {
        case 'pageview': {
          action.payload.response.forEach((item: any) => {
            // const caseSlug = item.andrew_id_1 + ':' + item.andrew_id_2;
            const caseSlug = item.person_name_1 + ':' + item.person_name_2;

            caseMetaData.push({
              studentInfo: [
                { id: item.person_name_1 },
                { id: item.person_name_2 },
              ],
              caseInfo: {
                overlapPercentage: item.overlap_percentage,
                numberOfOverlapPages: item.num_overlapping_pages,
                numberOfSyncPages: item.num_sync_pageviews,
                overlapSeconds: item.overlap_secs,
              },
            });

            caseCheckStatusMap[caseSlug] =
              checkStatusMap[caseSlug] || 'NotChecked';
          });
          break;
        }

        case 'time_outlier': {
          action.payload.response.forEach((item: any) => {
            const caseSlug = item.person_id;

            caseMetaData.push({
              studentInfo: [{ id: item.person_id }],
              caseInfo: {
                scoreObtained: item.score_obtained,
                timeSpent: item.time_duration_minutes,
              },
            });

            caseCheckStatusMap[caseSlug] =
              checkStatusMap[caseSlug] || 'NotChecked';
          });
          break;
        }

        default:
          break;
      }

      state.caseMetaData = caseMetaData;
      state.caseCheckStatusMap = caseCheckStatusMap;
    },
    updateCaseCheckStatusMap: (state, action: PayloadAction<any>) => {
      const caseCheckStatusMap = { ...state.caseCheckStatusMap };
      caseCheckStatusMap[action.payload.caseId] = action.payload.checkStatus;
      state.caseCheckStatusMap = caseCheckStatusMap;
    },
    updateNavBarCasesByFilter: (state) => {
      // TODO: update CaseMetaData structure to have quicker lookup
      let filteredCases: CaseMetaData[] = [];
      const caseMetaData: CaseMetaData[] = Array.from(state.caseMetaData);

      switch (state.navbarActiveCaseFilter.value) {
        case 'none': {
          filteredCases = caseMetaData;
          break;
        }

        case 'notChecked': {
          caseMetaData.forEach((caseMetaData: CaseMetaData) => {
            const caseId = caseMetaData.studentInfo
              .map((obj) => obj.id)
              .join(':');
            if (state.caseCheckStatusMap[caseId] === 'NotChecked')
              filteredCases.push(caseMetaData);
          });
          break;
        }

        case 'marked': {
          caseMetaData.forEach((caseMetaData: CaseMetaData) => {
            const caseId = caseMetaData.studentInfo
              .map((obj) => obj.id)
              .join(':');
            if (state.caseCheckStatusMap[caseId] === 'Marked')
              filteredCases.push(caseMetaData);
          });
          break;
        }

        case 'topSuspicious': {
          // only supports pageview by now
          caseMetaData.sort((a: CaseMetaData, b: CaseMetaData) => {
            if (a.caseInfo.overlapPercentage > b.caseInfo.overlapPercentage)
              return -1;
            if (a.caseInfo.overlapPercentage < b.caseInfo.overlapPercentage)
              return 1;
            return 0;
          });
          const overlapPercentageNumber = Math.ceil(
            caseMetaData.length *
              (state.topSuspiciousFilterSetting['overlapPercentage'] / 100),
          );
          const topOverlapPercentageCases = caseMetaData.slice(
            0,
            overlapPercentageNumber,
          );
          caseMetaData.sort((a: CaseMetaData, b: CaseMetaData) => {
            if (
              a.caseInfo.numberOfOverlapPages > b.caseInfo.numberOfOverlapPages
            )
              return -1;
            if (
              a.caseInfo.numberOfOverlapPages < b.caseInfo.numberOfOverlapPages
            )
              return 1;
            return 0;
          });
          const numberOfOverlapPagesNumber = Math.ceil(
            caseMetaData.length *
              (state.topSuspiciousFilterSetting['numberOfOverlapPages'] / 100),
          );
          const topNumberOfOverlapPagesCases = caseMetaData.slice(
            0,
            numberOfOverlapPagesNumber,
          );
          caseMetaData.sort((a: CaseMetaData, b: CaseMetaData) => {
            if (a.caseInfo.numberOfSyncPages > b.caseInfo.numberOfSyncPages)
              return -1;
            if (a.caseInfo.numberOfSyncPages < b.caseInfo.numberOfSyncPages)
              return 1;
            return 0;
          });
          const numberOfSyncPagesNumber = Math.ceil(
            caseMetaData.length *
              (state.topSuspiciousFilterSetting['numberOfSyncPages'] / 100),
          );
          const topNumberOfSyncPagesCases = caseMetaData.slice(
            0,
            numberOfSyncPagesNumber,
          );
          caseMetaData.sort((a: CaseMetaData, b: CaseMetaData) => {
            if (a.caseInfo.overlapSeconds > b.caseInfo.overlapSeconds)
              return -1;
            if (a.caseInfo.overlapSeconds < b.caseInfo.overlapSeconds) return 1;
            return 0;
          });
          const overlapSecondsNumber = Math.ceil(
            caseMetaData.length *
              (state.topSuspiciousFilterSetting['overlapSeconds'] / 100),
          );
          const topOverlapSecondsCases = caseMetaData.slice(
            0,
            overlapSecondsNumber,
          );
          const combinedCases = topOverlapPercentageCases.concat(
            topNumberOfOverlapPagesCases,
            topNumberOfSyncPagesCases,
            topOverlapSecondsCases,
          );
          filteredCases = Array.from(new Set(combinedCases));
          break;
        }
      }
      state.filteredCases = filteredCases;
    },
    updateNavBarCasesOrder: (state) => {
      const navbarCases: SelectOption[] = [];
      const filteredCases: CaseMetaData[] = Array.from(state.filteredCases);

      switch (state.navbarActiveCaseType.value) {
        case 'pageview': {
          // sort
          switch (state.navbarActiveCaseOrder.value) {
            case 'overlapPercentage': {
              filteredCases.sort((a: CaseMetaData, b: CaseMetaData) => {
                const aValue = Number(a.caseInfo.overlapPercentage);
                const bValue = Number(b.caseInfo.overlapPercentage);
                if (aValue > bValue) return -1;
                if (aValue < bValue) return 1;
                return 0;
              });
              break;
            }

            case 'numberOfOverlapPages': {
             filteredCases.sort((a: CaseMetaData, b: CaseMetaData) => {
               const aValue = Number(a.caseInfo.numberOfOverlapPages);
               const bValue = Number(b.caseInfo.numberOfOverlapPages);
               if (aValue > bValue) return -1;
               if (aValue < bValue) return 1;
               return 0;
             });
              break;
            }

            case 'numberOfSyncPages': {
             filteredCases.sort((a: CaseMetaData, b: CaseMetaData) => {
               const aValue = Number(a.caseInfo.numberOfSyncPages);
               const bValue = Number(b.caseInfo.numberOfSyncPages);
               if (aValue > bValue) return -1;
               if (aValue < bValue) return 1;
               return 0;
             });
              break;
            }

            case 'overlapSeconds': {
             filteredCases.sort((a: CaseMetaData, b: CaseMetaData) => {
               const aValue = Number(a.caseInfo.overlapSeconds);
               const bValue = Number(b.caseInfo.overlapSeconds);
               if (aValue > bValue) return -1;
               if (aValue < bValue) return 1;
               return 0;
             });
              break;
            }
          }
          // update navbar
          filteredCases.forEach((data: CaseMetaData) => {
            const matchId =
              data.studentInfo[0].id + ':' + data.studentInfo[1].id;
            navbarCases.push({
              value: matchId,
              label:
                data.caseInfo[state.navbarActiveCaseOrder.value] +
                '|' +
                state.caseCheckStatusMap[matchId] +
                '|' +
                matchId,
            });
          });
          break;
        }

        case 'time_outlier': {
          // sort
          switch (state.navbarActiveCaseOrder.value) {
            case 'timeSpent': {
              filteredCases.sort((a: CaseMetaData, b: CaseMetaData) => {
                if (a.caseInfo.timeSpent < b.caseInfo.timeSpent) return -1;
                if (a.caseInfo.timeSpent > b.caseInfo.timeSpent) return 1;
                return 0;
              });
              break;
            }
            case 'scoreObtained': {
              filteredCases.sort((a: CaseMetaData, b: CaseMetaData) => {
                if (a.caseInfo.scoreObtained > b.caseInfo.scoreObtained)
                  return -1;
                if (a.caseInfo.scoreObtained < b.caseInfo.scoreObtained)
                  return 1;
                return 0;
              });
              break;
            }
          }
          // update navbar
          filteredCases.forEach((data: CaseMetaData) => {
            const matchId = data.studentInfo[0].id;
            navbarCases.push({
              value: matchId,
              label:
                data.caseInfo[state.navbarActiveCaseOrder.value] +
                '|' +
                state.caseCheckStatusMap[matchId] +
                '|' +
                matchId,
            });
          });
          break;
        }
      }

      state.navbarCases = navbarCases;
      if (navbarCases.length > 0) {
        state.navbarActiveCase = navbarCases[0];
        state.activeCaseMetaData = filteredCases[0];

        state.activeCaseMetaDataIndex = 0;
      } else {
        state.navbarActiveCase = initialState.navbarActiveCase;
        state.activeCaseMetaData = initialState.activeCaseMetaData;

        state.activeCaseMetaDataIndex = initialState.activeCaseMetaDataIndex;
      }
      state.caseMetaDataStatus = true;
      state.caseMetaDataError = '';
    },
    updateCaseMetaDataError: (state, action: PayloadAction<any>) => {
      state.caseMetaData = initialState.caseMetaData;
      state.navbarCases = initialState.navbarCases;
      state.filteredCases = initialState.filteredCases;
      state.navbarActiveCase = initialState.navbarActiveCase;
      state.activeCaseMetaData = initialState.activeCaseMetaData;

      state.activeCaseMetaDataIndex = initialState.activeCaseMetaDataIndex;
      state.caseMetaDataStatus = false;
      state.caseMetaDataError = action.payload;
      state.caseCheckStatusMap = initialState.caseCheckStatusMap;
    },
    cleanCaseMetaData: (state) => {
      state.caseMetaData = initialState.caseMetaData;
      state.navbarCases = initialState.navbarCases;
      state.filteredCases = initialState.filteredCases;
      state.navbarActiveCase = initialState.navbarActiveCase;
      state.caseMetaDataStatus = initialState.caseMetaDataStatus;
      state.caseMetaDataError = initialState.caseMetaDataError;
      state.caseCheckStatusMap = initialState.caseCheckStatusMap;
    },
    updateScopeMetaData: (state) => {
      const scopeMetaData: ScopeMetaData[] = [{ label: 'Quiz', value: 'quiz' }];
      const navbarScopes: SelectOption[] = [{ label: 'Quiz', value: 'quiz' }];
      state.scopeMetaData = scopeMetaData;
      state.navbarScopes = navbarScopes;
      state.scopeMetaDataStatus = true;
      state.scopeMetaDataError = '';
      state.navbarActiveScope = { label: 'Quiz', value: 'quiz' };
    },
    updateScopeMetaDataError: (state, action) => {
      state.scopeMetaDataError = action.payload;
      state.scopeMetaData = initialState.scopeMetaData;
      state.navbarScopes = initialState.navbarScopes;
      state.scopeMetaDataStatus = initialState.scopeMetaDataStatus;
      state.navbarActiveScope = initialState.navbarActiveScope;
    },
    updateActiveScope: (state, action) => {
      state.navbarActiveScope = action.payload;
    },
    cleanScopeMetaData: (state) => {
      state.scopeMetaData = initialState.scopeMetaData;
      state.navbarScopes = initialState.navbarScopes;
      state.navbarActiveScope = initialState.navbarActiveScope;
      state.scopeMetaDataStatus = initialState.scopeMetaDataStatus;
      state.scopeMetaDataError = initialState.scopeMetaDataError;
    },
    updateCaseTypeMetaData: (state, action) => {
      const caseTypeMetaData: CaseTypeMetaData[] = [
        { label: 'Page View', value: 'pageview' },
        { label: 'Time Outlier', value: 'time_outlier' },
      ];
      const navbarCaseTypes: SelectOption[] = [
        { label: 'Page View', value: 'pageview' },
        { label: 'Time Outlier', value: 'time_outlier' },
      ];
      state.caseTypeMetaData = caseTypeMetaData;
      state.navbarCaseTypes = navbarCaseTypes;
      state.caseTypeMetaDataStatus = true;
      state.caseTypeMetaDataError = '';
      state.navbarActiveCaseType = { label: 'Page View', value: 'pageview' };
    },
    updateCaseTypeMetaDataError: (state, action) => {
      state.caseTypeMetaDataError = action.payload;
      state.caseTypeMetaData = initialState.caseTypeMetaData;
      state.navbarCaseTypes = initialState.navbarCaseTypes;
      state.caseTypeMetaDataStatus = initialState.caseTypeMetaDataStatus;
      state.navbarActiveCaseType = initialState.navbarActiveCaseType;
    },
    updateActiveCaseType: (state, action) => {
      state.navbarActiveCaseType = action.payload;
    },
    cleanCaseTypeMetaData: (state) => {
      state.caseTypeMetaData = initialState.caseTypeMetaData;
      state.navbarCaseTypes = initialState.navbarCaseTypes;
      state.navbarActiveCaseType = initialState.navbarActiveCaseType;
      state.caseTypeMetaDataStatus = initialState.caseTypeMetaDataStatus;
      state.caseTypeMetaDataError = initialState.caseTypeMetaDataError;
    },
    updateCaseFilterMetaData: (state) => {
      const caseFilterMetaData: CaseFilterMetaData[] = [];
      const navbarCaseFilters: SelectOption[] = [];
      caseFilterMetaData.push({ label: 'None', value: 'none' });
      caseFilterMetaData.push({ label: 'Not Cleared', value: 'notChecked' });
      caseFilterMetaData.push({ label: 'Marked', value: 'marked' });
      navbarCaseFilters.push({ label: 'None', value: 'none' });
      navbarCaseFilters.push({ label: 'Not Cleared', value: 'notChecked' });
      navbarCaseFilters.push({ label: 'Marked', value: 'marked' });
      switch (state.navbarActiveCaseType.value) {
        case 'pageview': {
          caseFilterMetaData.push({
            label: 'Top Suspicious',
            value: 'topSuspicious',
          });
          navbarCaseFilters.push({
            label: 'Top Suspicious',
            value: 'topSuspicious',
          });
          break;
        }

        case 'time_outlier': {
          break;
        }
      }

      state.caseFilterMetaData = caseFilterMetaData;
      state.navbarCaseFilters = navbarCaseFilters;
      state.caseFilterMetaDataStatus = true;
      state.caseFilterMetaDataError = '';
      state.navbarActiveCaseFilter = navbarCaseFilters[0];
    },
    updateCaseFilterMetaDataError: (state, action) => {
      state.caseFilterMetaDataError = action.payload;
      state.caseFilterMetaDataStatus = false;
      state.caseFilterMetaData = initialState.caseFilterMetaData;
      state.navbarCaseFilters = initialState.navbarCaseFilters;
      state.caseFilterMetaDataStatus = initialState.caseFilterMetaDataStatus;
      state.navbarActiveCaseFilter = initialState.navbarActiveCaseFilter;
      state.topSuspiciousFilterSetting =
        initialState.topSuspiciousFilterSetting;
    },
    updateActiveCaseFilter: (state, action) => {
      state.navbarActiveCaseFilter = action.payload;
    },
    cleanCaseFilterMetaData: (state) => {
      state.caseFilterMetaData = initialState.caseFilterMetaData;
      state.navbarCaseFilters = initialState.navbarCaseFilters;
      state.navbarActiveCaseFilter = initialState.navbarActiveCaseFilter;
      state.caseFilterMetaDataStatus = initialState.caseFilterMetaDataStatus;
      state.caseFilterMetaDataError = initialState.caseFilterMetaDataError;
      state.topSuspiciousFilterSetting =
        initialState.topSuspiciousFilterSetting;
    },
    updateTopSuspiciousFilterSetting: (state, action) => {
      state.topSuspiciousFilterSetting = action.payload;
    },
    updateCaseOrderMetaData: (state) => {
      let caseOrderMetaData: CaseOrderMetaData[] = [];
      let navbarCaseOrders: SelectOption[] = [];
      switch (state.navbarActiveCaseType.value) {
        case 'pageview': {
          caseOrderMetaData = [
            { label: 'Overlap Numbers', value: 'numberOfOverlapPages' },
            { label: 'Synchronous Steps', value: 'numberOfSyncPages' },
            { label: 'Overlapping Percentage', value: 'overlapPercentage' },
            { label: 'Overlap Time (Sec)', value: 'overlapSeconds' },
          ];
          navbarCaseOrders = [
            { label: 'Overlap Numbers', value: 'numberOfOverlapPages' },
            { label: 'Synchronous Steps', value: 'numberOfSyncPages' },
            { label: 'Overlapping Percentage', value: 'overlapPercentage' },
            { label: 'Overlap Time (Sec)', value: 'overlapSeconds' },
          ];
          state.navbarActiveCaseOrder = navbarCaseOrders[2];
          break;
        }
        case 'time_outlier': {
          caseOrderMetaData = [
            { label: 'Minutes Spent', value: 'timeSpent' },
            { label: 'Score Obtained', value: 'scoreObtained' },
          ];
          navbarCaseOrders = [
            { label: 'Minutes Spent', value: 'timeSpent' },
            { label: 'Score Obtained', value: 'scoreObtained' },
          ];
          state.navbarActiveCaseOrder = navbarCaseOrders[0];
          break;
        }

        default: {
          break;
        }
      }
      state.caseOrderMetaData = caseOrderMetaData;
      state.navbarCaseOrders = navbarCaseOrders;
      state.caseOrderMetaDataStatus = true;
      state.caseOrderMetaDataError = '';
    },
    updateCaseOrderMetaDataError: (state, action) => {
      state.caseOrderMetaDataError = action.payload;
      state.caseOrderMetaData = initialState.caseOrderMetaData;
      state.navbarCaseOrders = initialState.navbarCaseOrders;
      state.caseOrderMetaDataStatus = initialState.caseOrderMetaDataStatus;
      state.navbarActiveCaseOrder = initialState.navbarActiveCaseOrder;
    },
    updateActiveCaseOrder: (state, action) => {
      state.navbarActiveCaseOrder = action.payload;
    },
    cleanCaseOrderMetaData: (state) => {
      state.caseOrderMetaData = initialState.caseOrderMetaData;
      state.navbarCaseOrders = initialState.navbarCaseOrders;
      state.navbarActiveCaseOrder = initialState.navbarActiveCaseOrder;
      state.caseOrderMetaDataStatus = initialState.caseOrderMetaDataStatus;
      state.caseOrderMetaDataError = initialState.caseOrderMetaDataError;
    },
  },
});

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

export const getCheatCheckingQuizzesMetadata =
  (courseSlug: string): AppThunk =>
    async (dispatch) => {
      // create jwt token for auth
      try{
      const cookieValue = getStorageValue('jwt', 'baljit');
      const headers = new Headers();
      headers.append('x-access-token', cookieValue);
      // Get quiz from URL if it exists
      const quizFromURL = window.location.pathname.split('/')[4];

      const response = await fetch(
        `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/quizzes/`,
        {
          method: 'GET',
          headers: headers,
        });
    
      if (response.ok) {
        const data = await response.json();
        dispatch(updateQuizMetaData(data));
        
        // If we have a quiz in the URL and it exists in our quiz list, select it
        if (quizFromURL) {
          const quizMatch = data.find((quiz: any) => quiz.module_slug === quizFromURL);
          if (quizMatch) {
            dispatch(updateActiveQuiz({
              value: quizMatch.module_slug,
              label: quizMatch.module_name
            }));
          }
        }
      }
    }
      catch(error) {
        dispatch(updateQuizMetaDataError(error));
      };
  };

export const getCheatCheckingMatchMetaData =
  (
    courseValue: string,
    quizSlug: string,
    caseType: string,
    checkStatusMap: Record<string, unknown>,
  ): AppThunk =>
  (dispatch) => {
    const checkStatusMapKey =
      courseValue + '_' + 'quiz' + '_' + quizSlug + '_' + caseType;

    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);

    // This call already provides certain match order
    switch (caseType) {
      case 'pageview':
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/meta/cheatchecking/quiz/${quizSlug}/matches/`,
          { method: 'GET', headers: headers },
        )
          .then((response: any) => {
            return response.json();
          })
          .then((response) => {
            const input = {
              response: response,
              checkStatusMap: checkStatusMap[checkStatusMapKey] || {},
            };
            dispatch(updateCaseMetaData(input));
          })
          .catch((error) => {
            dispatch(updateCaseMetaDataError(error));
          });
        break;
      case 'time_outlier':
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/meta/cheatchecking/quiz/${quizSlug}/high_score_in_short_time/`,
          { method: 'GET', headers: headers },
        )
          .then((response: any) => {
            return response.json();
          })
          .then((response) => {
            const input = {
              response: response,
              checkStatusMap: checkStatusMap[checkStatusMapKey] || {},
            };
            dispatch(updateCaseMetaData(input));
          })
          .catch((error) => {
            dispatch(updateCaseMetaDataError(error));
          });
        break;
      default:
        break;
    }
  };

// Quiz
export const navbarQuizzes = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarQuizzes;
export const navbarActiveQuiz = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarActiveQuiz;
export const quizMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.quizMetaData;
export const quizMetaDataStatus = (state: RootState) =>
  state.navbarQuizCheatChecking.quizMetaDataStatus;
export const quizMetaDataError = (state: RootState) =>
  state.navbarQuizCheatChecking.quizMetaDataError;
// Case
export const navbarCases = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarCases;
export const filteredCases = (state: RootState) =>
  state.navbarQuizCheatChecking.filteredCases;
export const navbarActiveCase = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarActiveCase;
export const activeCaseMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.activeCaseMetaData;
export const activeCaseMetaDataIndex = (state: RootState) =>
  state.navbarQuizCheatChecking.activeCaseMetaDataIndex;
export const caseMetaDataStatus = (state: RootState) =>
  state.navbarQuizCheatChecking.caseMetaDataStatus;
export const caseMetaDataError = (state: RootState) =>
  state.navbarQuizCheatChecking.caseMetaDataError;
export const caseMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.caseMetaData;
// Scope
export const scopeMetaDataStatus = (state: RootState) =>
  state.navbarQuizCheatChecking.scopeMetaDataStatus;
export const scopeMetaDataError = (state: RootState) =>
  state.navbarQuizCheatChecking.scopeMetaDataError;
export const scopeMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.scopeMetaData;
export const navbarScopes = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarScopes;
export const navbarActiveScope = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarActiveScope;
// Case Type
export const caseTypeMetaDataStatus = (state: RootState) =>
  state.navbarQuizCheatChecking.caseTypeMetaDataStatus;
export const caseTypeMetaDataError = (state: RootState) =>
  state.navbarQuizCheatChecking.caseTypeMetaDataError;
export const caseTypeMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.caseTypeMetaData;
export const navbarCaseTypes = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarCaseTypes;
export const navbarActiveCaseType = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarActiveCaseType;
// Case Filter
export const caseFilterMetaDataStatus = (state: RootState) =>
  state.navbarQuizCheatChecking.caseFilterMetaDataStatus;
export const caseFilterMetaDataError = (state: RootState) =>
  state.navbarQuizCheatChecking.caseFilterMetaDataError;
export const caseFilterMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.caseFilterMetaData;
export const navbarCaseFilters = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarCaseFilters;
export const navbarActiveCaseFilter = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarActiveCaseFilter;
export const topSuspiciousFilterSetting = (state: RootState) =>
  state.navbarQuizCheatChecking.topSuspiciousFilterSetting;
// Case Order
export const caseOrderMetaDataStatus = (state: RootState) =>
  state.navbarQuizCheatChecking.caseOrderMetaDataStatus;
export const caseOrderMetaDataError = (state: RootState) =>
  state.navbarQuizCheatChecking.caseOrderMetaDataError;
export const caseOrderMetaData = (state: RootState) =>
  state.navbarQuizCheatChecking.caseOrderMetaData;
export const navbarCaseOrders = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarCaseOrders;
export const navbarActiveCaseOrder = (state: RootState) =>
  state.navbarQuizCheatChecking.navbarActiveCaseOrder;
export const isCaseFixed = (state: RootState) =>
  state.navbarQuizCheatChecking.isCaseFixed;
export const caseCheckStatusMap = (state: RootState) =>
  state.navbarQuizCheatChecking.caseCheckStatusMap;

export const {
  updateQuizMetaData,
  updateActiveQuiz,
  updateQuizMetaDataError,
  cleanQuizMetaData,
  updateCaseMetaData,
  updateCaseCheckStatusMap,
  updateNavBarCasesOrder,
  updateNavBarCasesByFilter,
  updateActiveCaseBySelect,
  updateActiveCaseByButton,
  updateIsCaseFixed,
  updateCaseMetaDataError,
  cleanCaseMetaData,
  updateScopeMetaData,
  updateScopeMetaDataError,
  updateActiveScope,
  cleanScopeMetaData,
  updateCaseTypeMetaData,
  updateCaseTypeMetaDataError,
  updateActiveCaseType,
  cleanCaseTypeMetaData,
  updateCaseFilterMetaData,
  updateCaseFilterMetaDataError,
  updateActiveCaseFilter,
  cleanCaseFilterMetaData,
  updateTopSuspiciousFilterSetting,
  updateCaseOrderMetaData,
  updateCaseOrderMetaDataError,
  updateActiveCaseOrder,
  cleanCaseOrderMetaData,
} = navbarQuizCheatCheckingSlice.actions;

export default navbarQuizCheatCheckingSlice.reducer;
