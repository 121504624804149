import Select from 'react-select';
import styled from 'styled-components';
import { useAppSelector } from '../app/hooks';

export interface SelectOption {
  value: string;
  label: string;
}

export const SkinnySelector = ({
  label,
  isSearchable,
  isDisabled,
  isMulti,
  optionsReduxSelector,
  selectedReduxSelector,
  handleSelect,
  placeholder,
}: {
  label: string;
  isSearchable: boolean;
  isDisabled: boolean;
  isMulti?: boolean;
  optionsReduxSelector: any;
  selectedReduxSelector: any;
  handleSelect: (value: any) => void;
  placeholder?: string;
}) => {
  const options = useAppSelector(optionsReduxSelector);
  const selected = useAppSelector(selectedReduxSelector);

  const getValue = () => {
    if (!selected) return null;
    if (isMulti) {
      return Array.isArray(selected) ? selected : [selected];
    }
    return Array.isArray(selected) ? selected[0] : selected;
  };

  return (
    <Container>
      <Label>{label}</Label>
      <SelectWrapper>
        <Select
          options={options as SelectOption[]}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          onChange={handleSelect}
          value={getValue()}
          placeholder={placeholder}
        />
      </SelectWrapper>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const Label = styled.label`
  text-align: right;
  padding-right: 1rem;
`;

const SelectWrapper = styled.div`
`;
