import {
  navbarActiveCourse,
  navbarActiveProject,
} from '../../app/navbarSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';

import ClassicNavBar from '../../components/Classic/ClassicNavBar';
import { LoadingLogo } from '../../svgs/LoadingLogo';
import RechartsBarChart from './RechartsBarChart';
import RechartsBarChartHorizontal from './RechartsBarChartHorizontal';
import RechartsLineChart from './RechartsLineChart';
import TaskSubmissionChart from './TaskSubmissionChart';
import Theme from '../../sharedStyles/Theme';

const { REACT_APP_BACKEND_URI } = process.env;
function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

const groupSubmissionByHour = (data) => {
  const groupedData = data.reduce((acc, curr) => {
    const eventDate = new Date(curr.event_time);
    const hour = `${eventDate.getUTCFullYear()}-${String(eventDate.getUTCMonth() + 1).padStart(2, '0')}-${String(eventDate.getUTCDate()).padStart(2, '0')} ${String(eventDate.getUTCHours()).padStart(2, '0')}:00`;

    if (!acc[hour]) {
      acc[hour] = 0;
    }
    acc[hour]++;
    return acc;
  }, {});
  const chartData = Object.entries(groupedData).map(([hour, count]) => ({
    hour,
    count,
  }));
  return chartData;
};

const cumulativeSubmissionByHour = (data) => {
  // Group the data by hour
  const groupedData = data.reduce((acc, curr) => {
    const eventDate = new Date(curr.event_time);
    const hour = `${eventDate.getUTCFullYear()}-${String(eventDate.getUTCMonth() + 1).padStart(2, '0')}-${String(eventDate.getUTCDate()).padStart(2, '0')} ${String(eventDate.getUTCHours()).padStart(2, '0')}:00`;

    if (!acc[hour]) {
      acc[hour] = 0;
    }
    acc[hour]++;
    return acc;
  }, {});

  // Calculate cumulative submissions
  let cumulativeCount = 0;
  const chartData = Object.entries(groupedData).map(([hour, count]) => {
    cumulativeCount += count;
    return {
      hour,
      count: cumulativeCount,
    };
  });
  return chartData;
};

const cumulativeSubmissionByHourForTasks = (data) => {
  // Group data by task_name
  const groupedByTaskName = data.reduce((acc, curr) => {
    const taskName = curr.task_name;

    if (!acc[taskName]) {
      acc[taskName] = [];
    }

    acc[taskName].push(curr);
    return acc;
  }, {});

  const chartData = [];

  const taskNames = Object.keys(groupedByTaskName);  // Extract task names

  // Process each task's data
  taskNames.forEach((taskName) => {
    const taskData = groupedByTaskName[taskName];

    // Group data by hour for each task_name
    const groupedData = taskData.reduce((acc, curr) => {
      const eventDate = new Date(curr.event_time);
      const hour = `${eventDate.getUTCFullYear()}-${String(eventDate.getUTCMonth() + 1).padStart(2, '0')}-${String(eventDate.getUTCDate()).padStart(2, '0')} ${String(eventDate.getUTCHours()).padStart(2, '0')}:00`;

      if (!acc[hour]) {
        acc[hour] = 0;
      }
      acc[hour]++;
      return acc;
    }, {});

    // Sort the grouped data by timestamp (hour)
    const sortedGroupedData = Object.entries(groupedData).sort(([hourA], [hourB]) => {
      return new Date(hourA).getTime() - new Date(hourB).getTime();
    });

    // Calculate cumulative submissions for this task
    let cumulativeCount = 0;
    sortedGroupedData.forEach(([hour, count]) => {
      cumulativeCount += count;

      // Find if the hour already exists in chartData
      let existingEntry = chartData.find(entry => entry.hour === hour);

      if (!existingEntry) {
        existingEntry = { hour };
        chartData.push(existingEntry);
      }

      // Add cumulative count for this taskName
      existingEntry[taskName] = cumulativeCount;
    });
  });

  // Final sort of chartData by hour to ensure all hours across all tasks are sorted
  chartData.sort((a, b) => new Date(a.hour).getTime() - new Date(b.hour).getTime());

  return { chartData, taskNames };  // Return both chart data and task names
};

const SingleProject = () => {
  const cookieValue = getStorageValue('jwt', 'baljit');
  const headers = new Headers();
  headers.append('x-access-token', cookieValue);

  const students = useAppSelector((state) => state.snapshot.students);
  const reduxDispatch = useAppDispatch();
  const courseValue = useAppSelector(navbarActiveCourse).value;
  const project = useAppSelector(navbarActiveProject);
  const [scores, setScores] = useState<any[]>([]);
  const [scoreIsReady, setScoreIsReady] = useState(false);
  const [submissionsPerHour, setSubmissionsPerHour] = useState<any[]>([]);
  const [cumulativeSubmissionsPerHour, setCumulativeSubmissionsPerHour] =
    useState<any[]>([]);
  const [submissionIsReady, setSubmissionIsReady] = useState(false);
  const [assessme, setAssessme] = useState<any[]>([]);
  const [assessmeIsReady, setAssessmeIsReady] = useState("");
  const [subtaskSubmission, setSubtaskSubmission] = useState<any[]>([]);
  const [subtaskNames, setSubtaskNames] = useState<string[]>([]);
  const [subtaskSubmissionIsReady, setSubtaskSubmissionIsReady] = useState(false);

  // Update project level data for called re-render
  useEffect(() => {
    let isMounted = true;

    const fetchScores = async () => {
      try {
        setScoreIsReady(false);
        const response = await fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/ScorePerStudent/${project.value}`,
          {
            method: 'GET',
            headers: headers,
          },
        );
        if (response.ok) {
          const scoreResponse = await response.json();
          console.log('[Score response]', scoreResponse); // [{email, module_score}]
          if (isMounted) {
            setScores(scoreResponse); // Directly set the scores without appending to prevScores
            setScoreIsReady(true);
          }
        } else {
          console.error('Error fetching scores status:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching scores:', error);
      }
    };

    const fetchAssessme = async () => {
      try {
        setAssessmeIsReady("loading");
        const response = await fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/${project.value}/stats/assessme/`,
          {
            method: 'GET',
            headers: headers,
          },
        );
        if (response.ok) {
          const AssessmeResponse = await response.json();
          if ("status" in AssessmeResponse && AssessmeResponse.status === "false") {
            setAssessmeIsReady("not enabled");
          }
          console.log('[Assessme response]', AssessmeResponse);
          setAssessme(AssessmeResponse);
          setAssessmeIsReady("ready");
        } else {
          console.error(
            'Error fetching Submissions status:',
            response.statusText,
          );
          setAssessmeIsReady("not enabled");
        }
      } catch (error) {
        console.error('Error fetching Submissions:', error);
        setAssessmeIsReady("not enabled");
      }
    };

    const fetchSubmissions = async () => {
      try {
        setSubmissionIsReady(false);
        const response = await fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/${project.value}/stats/submission/`,
          {
            method: 'GET',
            headers: headers,
          },
        );
        if (response.ok) {
          const submissionResponse = await response.json();
          console.log('[Submission response]', submissionResponse); // [{email, module_score}]
          const submissionPerHour = groupSubmissionByHour(submissionResponse);
          const cumulativeSubmission =
            cumulativeSubmissionByHour(submissionResponse);
          console.log('[cumulativeSubmission]', cumulativeSubmission);
          setSubmissionsPerHour(submissionPerHour); // Directly set the scores without appending to prevScores
          setCumulativeSubmissionsPerHour(cumulativeSubmission);
          setSubmissionIsReady(true);
        } else {
          console.error(
            'Error fetching Submissions status:',
            response.statusText,
          );
        }
      } catch (error) {
        console.error('Error fetching Submissions:', error);
      }
    };

    const fetchSubtaskSubmissions = async () => {
      try {
        setSubtaskSubmissionIsReady(false);
        const response = await fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/${project.value}/stats/taskSubmission/`,
          {
            method: 'GET',
            headers: headers,
          },
        );
        if (response.ok) {
          const taskSubmissionResponse = await response.json();
          console.log('[Subtask Submission response]', taskSubmissionResponse);
          const { chartData, taskNames } = cumulativeSubmissionByHourForTasks(taskSubmissionResponse);
          setSubtaskSubmission(chartData);
          setSubtaskNames(taskNames);
          setSubtaskSubmissionIsReady(true);
        } else {
          console.error(
            'Error fetching Submissions status:',
            response.statusText,
          );
        }
      } catch (error) {
        console.error('Error fetching Submissions:', error);
      }
    };

    if (courseValue) {
      setScoreIsReady(false);
      setSubmissionIsReady(false);
      fetchSubtaskSubmissions();
      fetchScores();
      fetchSubmissions();
      fetchAssessme();
    }

    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmounting
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseValue, project]);

  // // Render after scores are updated
  // if (!scoreIsReady) {
  //   return <LoadingLogo />;
  // }
  // const cumulativeSubmissionPlotData = [
  //   {
  //       ...rawData[0],
  //   },
  //   {
  //       ...rawData[1],
  //   },
  //   {
  //       ...rawData[2],
  //   },
  //   {
  //       ...rawData[3],
  //   },
  // ]

  console.log(scores);
  return (
    <Theme>
      <ClassicNavBar type="Per Project" />
      {assessmeIsReady === 'ready' ? (
        <RechartsBarChartHorizontal
          data={assessme}
          header={'Task Completions'}
        />
      ) : assessmeIsReady === 'loading' ? (
        <LoadingLogo />
      ) : assessmeIsReady === 'not enabled' ? (
        null
      ) : null}
      {scoreIsReady ? (
        <RechartsBarChart data={scores} header={'Score Distributions'} />
      ) : (
        <LoadingLogo />
      )}{' '}
      {/* Now using the updated scores state directly */}
      {submissionIsReady ? (
        <RechartsLineChart
          data={submissionsPerHour}
          header={'Submission per hour'}
        />
      ) : (
        <LoadingLogo />
      )}
      {submissionIsReady ? (
        <RechartsLineChart
          data={cumulativeSubmissionsPerHour}
          header={'Cumulative Submissions'}
        />
      ) : (
        <LoadingLogo />
      )}
      {subtaskSubmissionIsReady ? (
        <TaskSubmissionChart data={subtaskSubmission} header="Task Submissions" taskNames={subtaskNames} />
      ) : (
        <LoadingLogo />
      )}
    </Theme>
  );
};

export default SingleProject;
