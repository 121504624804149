import {
  Container,
  ContainerHeader,
  ContainerTitle,
  GridLayout,
  GriddedContainer,
} from '../../sharedStyles/sharedStyledComponents';
import {
  PageViewAnalysis,
  PageViewDetail,
  Student,
  SubquestionAttemptDetail,
  selectedStudents,
  updateSelectedStudents,
} from '../../app/quizCheatCheckingSlice';
import { navbarActiveCourse, navbarStudents } from '../../app/navbarSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AnswerTableWithProps } from '../../components/CheatChecking/Quizzes/AnswerTableWithProps';
import { Button } from '@material-ui/core';
import CheatCheckingNavBar from '../../components/CheatChecking/CheatCheckingNavBar';
import { LoadingLogo } from '../../svgs/LoadingLogo';
import { PageViewAnalysisTableWithProps } from '../../components/CheatChecking/Quizzes/PageViewAnalysisTableWithProps';
import { PageViewGraph } from '../../components/CheatChecking/Quizzes/PageViewGraph';
import { Selector } from '../../components/Selector';
import { SkinnySelector } from '../../components/SkinnySelector';
import { StudentInfoTableWithProps } from '../../components/CheatChecking/Quizzes/StudentInfoTableWithProps';
import Theme from '../../sharedStyles/Theme';
import { navbarActiveQuiz } from '../../app/navbarQuizCheatCheckingSlice';
import styled from 'styled-components';

const expected_students_number = 2;
const { REACT_APP_BACKEND_URI } = process.env;

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

const Comparisons = () => {
  // jwt tokens
  const cookieValue = getStorageValue('jwt', 'baljit');
  const headers = new Headers();
  headers.append('x-access-token', cookieValue);

  // state
  const [pageViewGraphsArray, setpageViewGraphsArray] = useState<any[]>([]);
  const [quizSlugs, setQuizSlugs] = useState<any[]>([]);
  const [quizTitles, setQuizTitles] = useState<{ [key: string]: string }>({}); // Add this state

  const [matches, setMatches] = useState<any[]>([]);
  const [matchesReady, setMatchesReady] = useState(false);
  const [caseHasHistory, setCaseHasHistory] = useState(true);

  // shared values among different pages
  const reduxDispatch = useAppDispatch();
  const selectedStudntsRedux = useAppSelector(selectedStudents);
  const courseSlug = useAppSelector(navbarActiveCourse).value;
  const studentLabelValueMapping = useAppSelector(navbarStudents);
  const history = useHistory();
  const location = useLocation();
  const quizSlug = useAppSelector(navbarActiveQuiz).value;

  /**
   * Handles update selectedStudent redux when selector get updated.
   */
  const handleStudentSelect = (selectedOptions: Student[]) => {
    // redux update
    reduxDispatch(updateSelectedStudents(selectedOptions));
  };

  /**
   * Handles copying the current page URL to the clipboard.
   */
  const handleCopyURL = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then()
      .catch((err) => {
        console.error('Failed to copy the URL: ', err);
      });
  };

  /**
   * Handles update url parameter.
   */
  useEffect(() => {
    // update url
    const selectedValues = selectedStudntsRedux
      .map((student) => student.value) // trim the email address, preserve andrew_id only
      .join(',');
    const currentQuiz = quizSlug;

    history.push({
      pathname: `${location.pathname}/${currentQuiz}`,
      search: selectedValues ? `?students=${selectedValues}` : '',
    });
  }, [selectedStudntsRedux]);

  /**
   * Handles loading url parameter once, get student's id (presented as email) from url
   */
  useEffect(() => {
    const getStudentIDFromURL = () => {
      // Check if 'students' parameter exists in the URL
      const hasStudentsParam = new URLSearchParams(location.search).has(
        'students',
      );
      if (!hasStudentsParam) {
        // If there is no 'students' parameter, exit the function
        return;
      }
      const studentIDsFromURL = new URLSearchParams(location.search).get(
        'students',
      );

      const studentIDs = studentIDsFromURL ? studentIDsFromURL.split(',') : [];
      const students = studentIDs.map((id) => {
        const student = studentLabelValueMapping.find(
          (student) => student.value === id,
        );
        return student
          ? { label: student.label, value: id }
          : { label: 'N/A', value: id };
      });
      reduxDispatch(updateSelectedStudents(students));
      // setSelectedStudentsLocal((prev)=> [...prev, students])
    };

    if (studentLabelValueMapping) {
      getStudentIDFromURL();
    }
  }, [studentLabelValueMapping]);

  /**
   * Handles loading quizSlug once.
   */
  useEffect(() => {
    // Define the async function to fetch quizzes
    const fetchQuizzesAndCMatchesData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/quizzes/`,
          {
            method: 'GET',
            headers: headers,
          },
        );
        if (response.ok) {
          const quizResponse = await response.json();

          const quizSlugs = quizResponse.map(({ module_slug }) => module_slug);
          // Create mapping of slug to title
          const titleMapping = quizResponse.reduce(
            (acc, quiz) => ({
              ...acc,
              [quiz.module_slug]: quiz.module_name || quiz.module_slug, // fallback to slug if no title
            }),
            {},
          );

          setQuizTitles(titleMapping);
          setQuizSlugs((prevquizSlugs) => [...prevquizSlugs, quizSlugs]);
        } else {
          console.error('Error fetching quizzes status:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      }
    };

    // Call the fetch function
    if (courseSlug) {
      fetchQuizzesAndCMatchesData();
    }
  }, [courseSlug]);

  /**
   * Handles loading matches once.
   */
  useEffect(() => {
    // a loop the get matches data
    const fetchMatches = async () => {
      const quizSlugsLocal = JSON.stringify(quizSlugs[0]).split(','); // can reference to this line, state array has an extra layer to unfold: console.log(`Matches = ${JSON.stringify(matches[0].length)}`)
      const matches = [];
      for (let quizSlug of quizSlugsLocal) {
        quizSlug = quizSlug
          .replace(/^\[|\]$/g, '')
          .trim()
          .replace(/^["']|["']$/g, ''); // TODO: update regex
        try {
          const response = await fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/cheatchecking/quiz/${quizSlug}/matches/`,
            {
              method: 'GET',
              headers: headers,
            },
          );

          if (response.ok) {
            const match = await response.json();
            if (match.length > 0) {
              matches.push({ quizSlug: quizSlug, cases: match });
            }
          } else {
            console.error(
              `Failed to fetch matches for ${quizSlug}: ${response.statusText}`,
            );
          }
        } catch (error) {
          console.error(`Error fetching matches for ${quizSlug}:`, error);
        }
      }
      //${JSON.stringify(matches)}
      setMatches((prevMatches) => [...prevMatches, matches]);
      setMatchesReady(true);
    };

    // only if quizSlugs have values, then fetch case meta data
    if (quizSlugs.length > 0) {
      // console.log("fetch matchhes~~~~~~")
      fetchMatches();
      // console.log("complete matchhes~~~~~~")
    }
  }, [quizSlugs]);

  /**
   * Handles loading pageViewData, answerTable, studentInfo.
   */
  useEffect(() => {
    const findMatcheCases = (matches, studentName1, studentName2) => {
      const results = [];
      for (const match of matches) {
        for (const caseItem of match.cases) {
          if (
            (caseItem.person_name_1 === studentName1 &&
              caseItem.person_name_2 === studentName2) ||
            (caseItem.person_name_1 === studentName2 &&
              caseItem.person_name_2 === studentName1)
          ) {
            results.push({
              quizSlug: match.quizSlug,
              case: caseItem,
              // studentID: [person_name_1, person_name_2]
            });
          }
        }
      }
      return results; // Return all matches found
    };

    const findPageViewData = async (casesAcrossQuizzes) => {
      for (const caseAcrossQuizzes of casesAcrossQuizzes) {
        const pageAnalysisPromises = [];
        const quizSlug = caseAcrossQuizzes.quizSlug;
        const studentIDs = [
          caseAcrossQuizzes.case.person_name_1,
          caseAcrossQuizzes.case.person_name_2,
        ];
        const studentID_1 = caseAcrossQuizzes.case.person_name_1;
        const studentID_2 = caseAcrossQuizzes.case.person_name_2;
        const pageViewAnalysis: PageViewAnalysis = {
          overlapPageNumber: -1,
          syncPageNumber: -1,
          overlapTimeDuration: -1,
          overlapPercentage: -1,
          pageViewDetails: {},
          subquestionAttemptDetails: {},
        };
        pageAnalysisPromises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentID_1}/pageviews/`,
            { method: 'GET', headers: headers },
          ),
        );
        pageAnalysisPromises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentID_2}/pageviews/`,
            { method: 'GET', headers: headers },
          ),
        );
        pageAnalysisPromises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/pageview/${studentID_1}/${studentID_2}/`,
            { method: 'GET', headers: headers },
          ),
        );
        pageAnalysisPromises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentID_1}/attempts/`,
            { method: 'GET', headers: headers },
          ),
        );
        pageAnalysisPromises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentID_2}/attempts/`,
            { method: 'GET', headers: headers },
          ),
        );
        await Promise.all(pageAnalysisPromises)
          .then((responses) => {
            return Promise.all(
              responses.map(async function (response) {
                if (response.ok) return response.json();
                else {
                  const errorText = await response.text();
                  throw new Error(`${errorText} for ${response.url}`);
                }
              }),
            );
          })
          .then((responses) => {
            responses.forEach((response, index) => {
              if (index < 2) {
                const studentID = studentIDs[index];
                if (response === undefined || response.length === 0) {
                  console.log('No page view data founded for ' + studentID);
                }
                const pageViewDetails: PageViewDetail[] = [];
                response.forEach((detail: any) => {
                  pageViewDetails.push({
                    time: detail.event_time,
                    pageNum: detail.sail_quiz_page,
                  });
                });
                pageViewAnalysis.pageViewDetails[studentID] = pageViewDetails;
              } else if (index === 2) {
                if (response.length !== 1) {
                  console.log('No matched page view analysis data');
                } else {
                  pageViewAnalysis.overlapPageNumber =
                    response[0].num_overlapping_pages;
                  pageViewAnalysis.syncPageNumber =
                    response[0].num_sync_pageviews;
                  pageViewAnalysis.overlapTimeDuration =
                    response[0].overlap_secs;
                  pageViewAnalysis.overlapPercentage =
                    response[0].overlap_percentage;
                }
              } else {
                const studentID = studentIDs[index - 3];
                console.log('// update  updateSubquestionAttemptDetails');
                if (response === undefined || response.length === 0) {
                  console.log(
                    'No subquestion attempt data founded for ' + studentID,
                  );
                }
                const subquestionAttemptDetails: SubquestionAttemptDetail[] =
                  [];
                response.forEach((detail: any) => {
                  subquestionAttemptDetails.push({
                    time: detail.event_time,
                    pageNum: detail.sail_quiz_page,
                  });
                });
                pageViewAnalysis.subquestionAttemptDetails[studentID] =
                  subquestionAttemptDetails;
              }
            });
          });

        // console.log(`page: ${JSON.stringify(pageViewAnalysis)}`)
        const data = pageViewData(pageViewAnalysis);
        const pageViewAnalysisObj = {
          overlapPageNumber: pageViewAnalysis.overlapPageNumber,
          syncPageNumber: pageViewAnalysis.syncPageNumber,
          overlapTimeDuration: pageViewAnalysis.overlapTimeDuration,
          overlapPercentage: pageViewAnalysis.overlapPercentage,
        };

        // fetch AnswerTable props
        const answerTablePromises = [];
        const answerAnalysisMap = {};
        const answerStatus = {};

        // get quiz answer
        await fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/stats/`,
          { method: 'GET', headers: headers },
        )
          .then((promise: any) => {
            return promise.json();
          })
          .then((response: any) => {
            response.forEach((item: any) => {
              item.question_pool_slug = 'question_slug';
              answerStatus[item.question_slug] = {
                questionPoolSlug: 'question_slug',
                subquestionSlug: item.question_slug,
                attemptsNumber: item.total_attempts,
                correctNumber: item.num_correct,
                correctPercent: item.percent_correct,
              };
            });
          });

        // get student's answer
        for (const idx in studentIDs) {
          answerTablePromises.push(
            fetch(
              `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[idx]}/answers/`,
              { method: 'GET', headers: headers },
            ),
          );
        }

        await Promise.all(answerTablePromises)
          .then((responses) => {
            return Promise.all(
              responses.map(async function (response) {
                if (response.ok) return response.json();
                else {
                  const errorText = await response.text();
                  throw new Error(`${errorText} for ${response.url}`);
                }
              }),
            );
          })
          .then((responses) => {
            responses.forEach((response, index) => {
              const studentID = studentIDs[index];
              for (const idx in response) {
                // response[idx].question_pool_slug = "Not available";
                if (
                  answerAnalysisMap[response[idx].question_pool_slug] ===
                  undefined
                ) {
                  answerAnalysisMap[response[idx].question_pool_slug] = {};
                }
                if (
                  answerAnalysisMap[response[idx].question_pool_slug][
                    response[idx].question_slug
                  ] === undefined
                ) {
                  answerAnalysisMap[response[idx].question_pool_slug][
                    response[idx].question_slug
                  ] = {
                    questionPoolSlug: response[idx].question_pool_slug,
                    subquestionSlug: response[idx].question_slug,
                    subquestionType: response[idx].question_type,
                    page: response[idx].page_number,
                    // studentSlug -> AnswerDetail
                    answerDetails: {},
                  };
                }
                answerAnalysisMap[response[idx].question_pool_slug][
                  response[idx].question_slug
                ].answerDetails[studentID] = {
                  answer: response[idx].answer,
                  correctness: response[idx].correctness,
                  type: response[idx].question_type,
                };
              }
              // dispatch(
              //   updateAnswerAnalysisMap({
              //     response: response,
              //     studentID: studentIDs[index],
              //   }),
              // );
            });
          });

        // get studentInfos
        const studentInfoPromise = [];
        const studentInfo = {};
        for (const idx in studentIDs) {
          studentInfoPromise.push(
            fetch(
              `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/student/${studentIDs[idx]}/`,
              { method: 'GET', headers: headers },
            ),
          );
          studentInfoPromise.push(
            fetch(
              `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/student/${studentIDs[idx]}/student_info/`,
              { method: 'GET', headers: headers },
            ),
          );
          studentInfoPromise.push(
            fetch(
              `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[idx]}/stats/`,
              { method: 'GET', headers: headers },
            ),
          );
        }
        await Promise.all(studentInfoPromise)
          .then((responses) => {
            return Promise.all(
              responses.map(async function (response) {
                if (response.ok) return response.json();
                else {
                  const errorText = await response.text();
                  throw new Error(`${errorText} for ${response.url}`);
                }
              }),
            );
          })
          .then((responses) => {
            responses.forEach((response, index) => {
              const studentID = studentIDs[index / 3];

              switch (index % 3) {
                case 0:
                  if (response.length === 0) {
                    console.log(
                      "No matched student's basic info for " + studentID,
                    );
                  } else if (response.length !== 1) {
                    console.log(
                      "Multiple matched student's basic info for " + studentID,
                    );
                  } else {
                    studentInfo[response[0].person_id] = {
                      ...studentInfo[response[0].person_id],
                      role: response[0].person_roles,
                      status: response[0].person_status,
                      usedGraceDayNumber: response[0].person_grace_days_used,
                    };
                  }
                  break;
                case 1:
                  if (response.length === 0) {
                    console.log(
                      "No matched student's c4 info for " + studentID,
                    );
                  } else if (response.length !== 1) {
                    console.log(
                      "Multiple matched student's c4 info for " + studentID,
                    );
                  } else {
                    studentInfo[response[0].person_id] = {
                      ...studentInfo[response[0].person_id],
                      firstName: response[0].first_name,
                      lastName: response[0].last_name,
                      college: response[0].college,
                      department: response[0].department,
                      course: response[0].course,
                      campus: response[0].campus,
                      team: response[0].team,
                    };
                  }
                  break;
                case 2:
                  if (response.length === 0) {
                    console.log(
                      "No matched student's quiz stats for " + studentID,
                    );
                  } else if (response.length !== 1) {
                    let quizDuration = '';
                    let quizScore = '';
                    for (const idx in response) {
                      quizDuration +=
                        response[idx].time_duration_minutes + ', ';
                      quizScore += response[idx].score_obtained + ', ';
                    }
                    studentInfo[response[0].person_id] = {
                      ...studentInfo[response[0].person_id],
                      quizTimeDuration: quizDuration.substring(
                        0,
                        quizDuration.length - 2,
                      ),
                      quizScore: quizScore.substring(0, quizScore.length - 2),
                    };
                    console.log(
                      "Multiple matched student's quiz stats for " + studentID,
                    );
                  } else {
                    studentInfo[response[0].person_id] = {
                      ...studentInfo[response[0].person_id],
                      quizTimeDuration: response[0].time_duration_minutes,
                      quizScore: response[0].score_obtained,
                    };
                  }
                  break;
              }
            });
          });

        const answerTableObj = {
          answerAnalysisMap: answerAnalysisMap,
          answerStatus: answerStatus,
          studentIDs: studentIDs,
          quizSlug: quizSlug,
        };

        // update pageViewGraphsArray
        const temp = pageViewGraphsArray;
        temp.push([data, pageViewAnalysisObj, answerTableObj, studentInfo]);
        setpageViewGraphsArray((prev) => [...prev, temp]);
      }
      // pageViewGraphsArray.map((graphData, index) => {
      //   console.log(`pageViewGraphsArray data : ${JSON.stringify(graphData[0])}, index: ${JSON.stringify(index)}`)
      // })
    };

    console.log(`Trigger data processing, clean state`);
    // clean the state
    setpageViewGraphsArray([]);

    if (
      selectedStudntsRedux.length == expected_students_number &&
      matchesReady &&
      quizSlug
    ) {
      const casesAcrossQuizzes = findMatcheCases(
        matches[0],
        selectedStudntsRedux[0].value,
        selectedStudntsRedux[1].value,
      ).filter((match) => match.quizSlug === quizSlug); // Filter for current quiz only

      if (casesAcrossQuizzes.length > 0) {
        setCaseHasHistory(true);
        findPageViewData(casesAcrossQuizzes);
      } else {
        setCaseHasHistory(false);
      }
      // try to find matches
      // if find matches, load pageView data, using /pageViews and /attempts
    }
  }, [selectedStudntsRedux, matchesReady, quizSlug]);

  /**
   * create pageViewData for PageViewGraph.
   */
  const pageViewData = (pageViewAnalysis) => {
    const data: any[] = [];
    const colorMap = {
      0: '#67D7B6',
      1: 'orange',
    };
    Object.keys(pageViewAnalysis.pageViewDetails).forEach(
      (studentID, index) => {
        // console.log(`studentID: ${studentID}, index: ${index}` )
        const lineData: any = {
          x: [],
          y: [],
          type: 'scatter',
          mode: 'lines+markers',
          marker: {
            color: colorMap[index],
          },
          line: { shape: 'hv' },
          name: studentID,
          minX: undefined,
          maxX: undefined,
          minY: undefined,
          maxY: undefined,
        };
        pageViewAnalysis.pageViewDetails[studentID].forEach(
          (pageViewDetail: PageViewDetail) => {
            const timestamp = new Date(pageViewDetail.time);
            const pageNum = Number(pageViewDetail.pageNum);
            lineData.x.push(timestamp);
            lineData.y.push(pageNum);
            if (lineData.minX === undefined || lineData.minX > timestamp)
              lineData.minX = timestamp;
            if (lineData.maxX === undefined || lineData.maxX < timestamp)
              lineData.maxX = timestamp;
            if (lineData.minY === undefined || lineData.minY > pageNum)
              lineData.minY = pageNum;
            if (lineData.maxY === undefined || lineData.maxY < pageNum)
              lineData.maxY = pageNum;
          },
        );
        data.push(lineData);
      },
    );
    Object.keys(pageViewAnalysis.subquestionAttemptDetails).forEach(
      (studentID, index) => {
        // console.log(`studentID: ${studentID}, index: ${index}`)
        const lineData: any = {
          x: [],
          y: [],
          type: 'scatter',
          mode: 'markers',
          visible: 'legendonly',
          marker: {
            size: 9,
            symbol: 'triangle-down',
            color: colorMap[index],
          },
          name: 'Attempts ' + studentID,
          minX: undefined,
          maxX: undefined,
          minY: undefined,
          maxY: undefined,
        };
        pageViewAnalysis.subquestionAttemptDetails[studentID].forEach(
          (subquestionAttemptDetail: SubquestionAttemptDetail) => {
            const timestamp = new Date(subquestionAttemptDetail.time);
            const pageNum = Number(subquestionAttemptDetail.pageNum);
            lineData.x.push(timestamp);
            lineData.y.push(pageNum);
            if (lineData.minX === undefined || lineData.minX > timestamp)
              lineData.minX = timestamp;
            if (lineData.maxX === undefined || lineData.maxX < timestamp)
              lineData.maxX = timestamp;
            if (lineData.minY === undefined || lineData.minY > pageNum)
              lineData.minY = pageNum;
            if (lineData.maxY === undefined || lineData.maxY < pageNum)
              lineData.maxY = pageNum;
          },
        );
        data.push(lineData);
      },
    );
    return data;
  };

  return (
    <Theme>
      <CheatCheckingNavBar />
      <StyledComparisons>
        <GridLayout
          gridTemplateAreas='"selector" "content"'
          height="100vh"
          margin="0"
          gridColumnGap="1%"
          gridRowGap="0"
        >
          <StyledContainer>
            <SkinnySelector
              label="Select 2 students to see their student comparison analysis"
              isSearchable={true}
              isDisabled={false}
              optionsReduxSelector={navbarStudents}
              selectedReduxSelector={selectedStudents}
              handleSelect={handleStudentSelect}
              isMulti={true}
              placeholder="Select 2 students"
            />
            <CopyButton
              variant="contained"
              color="primary"
              onClick={handleCopyURL}
            >
              Copy URL for Report
            </CopyButton>
          </StyledContainer>
          <GriddedContainer gridClass="content">
            {pageViewGraphsArray.length > 0 ? (
              <ul>
                {pageViewGraphsArray.map((graphData, index) => {
                  return graphData[index] ? (
                    <li key={index}>
                      <Container
                        backgroundColor="#ffffff"
                        margin="0 0 1% 0"
                        hasBoxShadow={true}
                      >
                        <ContainerHeader>
                          <ContainerTitle>Quiz Name</ContainerTitle>
                          <div>
                            {quizTitles[graphData[index][2].quizSlug] ||
                              graphData[index][2].quizSlug}
                            {/* ({graphData[index][2].quizSlug}) */}
                          </div>{' '}
                        </ContainerHeader>
                      </Container>

                      <Container
                        backgroundColor="#ffffff"
                        margin="0 0 1% 0"
                        hasBoxShadow={true}
                      >
                        <ContainerHeader>
                          <ContainerTitle>Students' Info</ContainerTitle>
                        </ContainerHeader>
                        <StudentInfoTableWithProps
                          studentInfos={graphData[index][3]}
                          studentIDs={graphData[index][2].studentIDs}
                        />
                      </Container>

                      <Container
                        backgroundColor="#ffffff"
                        height="70vh"
                        minHeight="700px"
                        margin="0 0 1% 0"
                        hasBoxShadow={true}
                      >
                        <ContainerHeader>
                          <ContainerTitle>Page View Analysis</ContainerTitle>
                        </ContainerHeader>
                        <>
                          <PageViewAnalysisTableWithProps
                            data={graphData[index]}
                          />
                          <ContainerHeader>
                            <ContainerTitle>Page View Timeline</ContainerTitle>
                          </ContainerHeader>
                        </>
                        <PageViewGraph
                          data={graphData[index]}
                          length={pageViewGraphsArray.length}
                        />
                      </Container>

                      <Container backgroundColor="#ffffff" hasBoxShadow={true}>
                        <ContainerHeader>
                          <ContainerTitle>Answer Analysis</ContainerTitle>
                        </ContainerHeader>
                        <AnswerTableWithProps
                          studentAnswerAnalyisMap={
                            graphData[index][2].answerAnalysisMap
                          }
                          quizAnswerStatus={graphData[index][2].answerStatus}
                          studentIDs={graphData[index][2].studentIDs}
                        />
                      </Container>
                    </li>
                  ) : null;
                })}
              </ul>
            ) : selectedStudntsRedux.length < 2 ? (
              <p style={{ textAlign: 'center', margin: '1rem 0' }}>
                Please select 2 students from the dropdown above.
              </p>
            ) : !caseHasHistory ? (
              <p style={{ textAlign: 'center', margin: '1rem 0' }}>
                These two students don't have a shared history of quiz behavior
              </p>
            ) : (
              <LoadingLogo />
            )}
          </GriddedContainer>
        </GridLayout>
      </StyledComparisons>
    </Theme>
  );
};

const StyledComparisons = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;

  h2 {
    color: #333;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      background-color: #e0e0e0;
      margin: 5px 0;
      padding: 10px;
      border-radius: 4px;
    }
  }

  /* Add more styled elements as needed */
`;

// const SelectorContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 1rem;
// `;

const CopyButton = styled(Button)`
  height: 2rem;

  font-size: 0.875rem; /* Adjust font size for smaller button text */
  padding: 0.5rem 1rem; /* Adjust padding for smaller button */
  background-color: darkgreen !important; /* Dark green color */
  color: white !important; /* Text color */
  &:hover {
    background-color: green !important; /* Lighter green on hover */
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  align-self: start;
`;

export default Comparisons;
