import {TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";

import React from "react";
import {StyledTable} from "../../../sharedStyles/sharedStyledComponents";
import TableCell from "@material-ui/core/TableCell";
import {withStyles} from "@material-ui/core/styles";

export const PageViewAnalysisTableWithProps = (props: {data: any}) => {
    const pageViewAnalysisTableObj = props.data[1];
    
    if (!(pageViewAnalysisTableObj === undefined) ) {
        return (
            <TableContainer style={{
                padding: '1rem 1rem 1rem 1rem',
                width: '96%'
            }}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Overlap Numbers</StyledTableCell>
                            <StyledTableCell>Overlap Time (Sec)</StyledTableCell>
                            <StyledTableCell>Synchronous Steps</StyledTableCell>
                            <StyledTableCell>Overlapping Percentage</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell>{pageViewAnalysisTableObj.overlapPageNumber === -1 ? "/" : pageViewAnalysisTableObj.overlapPageNumber}</StyledTableCell>
                            <StyledTableCell>{pageViewAnalysisTableObj.overlapTimeDuration === -1 ? "/" : pageViewAnalysisTableObj.overlapTimeDuration}</StyledTableCell>
                            <StyledTableCell>{pageViewAnalysisTableObj.syncPageNumber === -1 ? "/" : pageViewAnalysisTableObj.syncPageNumber}</StyledTableCell>
                            <StyledTableCell>{pageViewAnalysisTableObj.overlapPercentage === -1 ? "/" : pageViewAnalysisTableObj.overlapPercentage + "%"}</StyledTableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </TableContainer>
        )
    }
    else {
        return (
            <TableContainer style={{
                padding: '1rem 1rem 1rem 1rem',
                width: '96%'
            }}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Overlap Numbers</StyledTableCell>
                            <StyledTableCell>Overlap Time (Sec)</StyledTableCell>
                            <StyledTableCell>Synchronous Steps</StyledTableCell>
                            <StyledTableCell>Overlap Percentage</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell>{"/"}</StyledTableCell>
                            <StyledTableCell>{"/"}</StyledTableCell>
                            <StyledTableCell>{"/"}</StyledTableCell>
                            <StyledTableCell>{"/"}</StyledTableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </TableContainer>
        )

    }
}
    

const StyledTableCell = withStyles((theme) => ({
    root: {
        fontSize: 14,
        fontWeight: 600,
        color: "#132C3E",
        fontFamily: "Fira Sans",
    },
    head: {
        backgroundColor: "#E8F9F4",
        padding: "1rem 1rem",
    },
    body: {
        padding: ".6rem 1rem",
    },
}))(TableCell);
