import {
    Container,
    ContainerHeader,
    ContainerTitle,
    GridLayout,
    GriddedContainer
} from "../../sharedStyles/sharedStyledComponents";
import {
    PageViewDetail,
    SubquestionAttemptDetail,
    answerAnalysisMapError,
    answerAnalysisMapStatus,
    getAnswerAnalysis,
    getAnswerStats,
    getPageViewAnalysis,
    getStudentInfo,
    pageViewAnalysis,
    pageViewAnalysisError,
    pageViewAnalysisStatus,
    updateSelectedStudents,
} from "../../app/quizCheatCheckingSlice";
import {
    activeCaseMetaData,
    caseMetaData,
    filteredCases,
    getCheatCheckingMatchMetaData,
    getCheatCheckingQuizzesMetadata,
    isCaseFixed,
    navbarActiveCaseFilter,
    navbarActiveCaseOrder,
    navbarActiveCaseType,
    navbarActiveQuiz,
    navbarActiveScope,
    updateCaseFilterMetaData,
    updateCaseOrderMetaData,
    updateCaseTypeMetaData,
    updateNavBarCasesByFilter,
    updateNavBarCasesOrder,
    updateScopeMetaData
} from "../../app/navbarQuizCheatCheckingSlice";
import { navbarActiveCourse, navbarStudents } from "../../app/navbarSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import {AnswerTable} from "../../components/CheatChecking/Quizzes/AnswerTable";
import CheatCheckingNavBar from '../../components/CheatChecking/CheatCheckingNavBar';
import {CheatCheckingSelectors} from "../../components/CheatChecking/CheatCheckingSelectors";
import {LoadingLogo} from "../../svgs/LoadingLogo";
import {PageViewAnalysisTable} from "../../components/CheatChecking/Quizzes/PageViewAnalysisTable";
import {PageViewGraph} from "../../components/CheatChecking/Quizzes/PageViewGraph";
import {StudentInfoTable} from "../../components/CheatChecking/Quizzes/StudentInfoTable";
import Theme from "../../sharedStyles/Theme";
import { useEffect } from "react";

const Quizzes = () => {
    const reduxDispatch = useAppDispatch();

    const courseSlug = useAppSelector(navbarActiveCourse).value;
    const studentLabelValueMapping = useAppSelector(navbarStudents)
    const navbarActiveScopeRedux =  useAppSelector(navbarActiveScope);
    const quizSlug = useAppSelector(navbarActiveQuiz).value;

    const navbarActiveCaseTypeRedux = useAppSelector(navbarActiveCaseType);
    const navbarActiveCaseFilterRedux = useAppSelector(navbarActiveCaseFilter);
    const navbarActiveCaseOrderRedux =  useAppSelector(navbarActiveCaseOrder);
    const filteredCasesRedux = useAppSelector(filteredCases);

    const activeCaseMetaDataRedux = useAppSelector(activeCaseMetaData);
    const caseMetaDataRedux = useAppSelector(caseMetaData);
    const isCaseFixedRedux = useAppSelector(isCaseFixed);


    const answerAnalysisMapStatusRedux = useAppSelector(answerAnalysisMapStatus);
    const answerAnalysisMapErrorRedux = useAppSelector(answerAnalysisMapError)

    const pageViewAnalysisStatusRedux = useAppSelector(pageViewAnalysisStatus);
    const pageViewAnalysisErrorRedux = useAppSelector(pageViewAnalysisError);
    const pageViewAnalysisRedux = useAppSelector(pageViewAnalysis);

    useEffect(() => {
        if (courseSlug !== '') {
            reduxDispatch(updateScopeMetaData())
        }
    }, [courseSlug])

    useEffect(() => {
        if (courseSlug !== '') {
            reduxDispatch(
                getCheatCheckingQuizzesMetadata(courseSlug)
            );
        }
    }, [navbarActiveScopeRedux])

    useEffect(() => {
        if (!isCaseFixedRedux && courseSlug !== '' && quizSlug !== '') {
            reduxDispatch(updateCaseTypeMetaData(quizSlug));
            reduxDispatch(getAnswerStats(courseSlug, quizSlug));
        }
    }, [quizSlug])

    useEffect(() => {
        if (isCaseFixedRedux && courseSlug !== '' && quizSlug !== '' && activeCaseMetaDataRedux.studentInfo.length > 0) {
            loadContents();
        }
    }, [quizSlug])

    useEffect(() => {
        if (courseSlug !== '' && quizSlug !== '') {

            const checkStatusMap = JSON.parse(localStorage.getItem('CheckStatusMap') || '{}')
            reduxDispatch(
                getCheatCheckingMatchMetaData(courseSlug, quizSlug, navbarActiveCaseTypeRedux.value, checkStatusMap)
            );
        }
    }, [navbarActiveCaseTypeRedux])

    useEffect(() => {
        if (courseSlug !== '' && quizSlug !== '' && caseMetaDataRedux.length >= 0) {
            reduxDispatch(updateCaseFilterMetaData());
            reduxDispatch(updateCaseOrderMetaData());
        }
    }, [caseMetaDataRedux])

    useEffect(() => {
        if (courseSlug !== '' && quizSlug !== '' && caseMetaDataRedux.length >= 0) {
            reduxDispatch(updateNavBarCasesByFilter());
        }
    }, [navbarActiveCaseFilterRedux])

    useEffect(() => {
        if (courseSlug !== '' && quizSlug !== '' && filteredCasesRedux.length >= 0) {
            reduxDispatch(updateNavBarCasesOrder());
        }
    }, [navbarActiveCaseOrderRedux, filteredCasesRedux])

    useEffect(() => {
        if (activeCaseMetaDataRedux.studentInfo.length >= 0) {
            loadContents();

            // update selectedStudents
            const students = activeCaseMetaDataRedux.studentInfo.map((studentInfo) => {
                const student = studentLabelValueMapping.find(
                (student: { value: string; }) => student.value === studentInfo.id);
                return student ? { value: student.value, label: studentInfo.id } : { value: 'N/A', label: studentInfo.id };
            });
            reduxDispatch(updateSelectedStudents(students))
        }
    }, [activeCaseMetaDataRedux])


    const loadContents = () => {
        switch (navbarActiveCaseTypeRedux.value) {
            case "pageview":
                if (activeCaseMetaDataRedux !== undefined && activeCaseMetaDataRedux.studentInfo.length === 2) {
                    reduxDispatch(getStudentInfo(courseSlug, quizSlug, [activeCaseMetaDataRedux.studentInfo[0].id, activeCaseMetaDataRedux.studentInfo[1].id]))
                    reduxDispatch(getAnswerAnalysis(courseSlug, quizSlug, [activeCaseMetaDataRedux.studentInfo[0].id, activeCaseMetaDataRedux.studentInfo[1].id]))
                    reduxDispatch(getPageViewAnalysis(courseSlug, quizSlug, navbarActiveCaseTypeRedux.value, [activeCaseMetaDataRedux.studentInfo[0].id, activeCaseMetaDataRedux.studentInfo[1].id]))
                }
                break;
            case "time_outlier":
                if (activeCaseMetaDataRedux !== undefined && activeCaseMetaDataRedux.studentInfo.length === 1) {
                    reduxDispatch(getStudentInfo(courseSlug, quizSlug, [activeCaseMetaDataRedux.studentInfo[0].id]))
                    reduxDispatch(getAnswerAnalysis(courseSlug, quizSlug, [activeCaseMetaDataRedux.studentInfo[0].id]))
                    reduxDispatch(getPageViewAnalysis(courseSlug, quizSlug, navbarActiveCaseTypeRedux.value, [activeCaseMetaDataRedux.studentInfo[0].id]))
                }
                break;
        }
    }


    const pageViewData = () => {
        if (pageViewAnalysisStatusRedux) {
            const data: any[] = []
            const colorMap = {
                0: '#67D7B6',
                1: 'orange'
            }
            Object.keys(pageViewAnalysisRedux.pageViewDetails).forEach((studentID, index) => {
                const lineData: any = {
                    x: [],
                    y: [],
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: {
                        color: colorMap[index]
                    },
                    line: {shape: 'hv'},
                    name: studentID,
                    minX: undefined,
                    maxX: undefined,
                    minY: undefined,
                    maxY: undefined
                }
                pageViewAnalysisRedux.pageViewDetails[studentID].forEach((pageViewDetail: PageViewDetail) => {
                    const timestamp = new Date(pageViewDetail.time);
                    const pageNum = Number(pageViewDetail.pageNum);
                    lineData.x.push(timestamp);
                    lineData.y.push(pageNum);
                    if (lineData.minX === undefined || lineData.minX > timestamp) lineData.minX = timestamp;
                    if (lineData.maxX === undefined || lineData.maxX < timestamp) lineData.maxX = timestamp;
                    if (lineData.minY === undefined || lineData.minY > pageNum) lineData.minY = pageNum;
                    if (lineData.maxY === undefined || lineData.maxY < pageNum) lineData.maxY = pageNum;
                })
                data.push(lineData);
            })
            Object.keys(pageViewAnalysisRedux.subquestionAttemptDetails).forEach((studentID, index) => {
                const lineData: any = {
                    x: [],
                    y: [],
                    type: 'scatter',
                    mode: 'markers',
                    visible: 'legendonly',
                    marker: {
                        size: 9,
                        symbol: 'triangle-down',
                        color: colorMap[index]
                    },
                    name: 'Attempts ' + studentID,
                    minX: undefined,
                    maxX: undefined,
                    minY: undefined,
                    maxY: undefined
                }
                pageViewAnalysisRedux.subquestionAttemptDetails[studentID].forEach((subquestionAttemptDetail: SubquestionAttemptDetail) => {
                    const timestamp = new Date(subquestionAttemptDetail.time);
                    const pageNum = Number(subquestionAttemptDetail.pageNum);
                    lineData.x.push(timestamp);
                    lineData.y.push(pageNum);
                    if (lineData.minX === undefined || lineData.minX > timestamp) lineData.minX = timestamp;
                    if (lineData.maxX === undefined || lineData.maxX < timestamp) lineData.maxX = timestamp;
                    if (lineData.minY === undefined || lineData.minY > pageNum) lineData.minY = pageNum;
                    if (lineData.maxY === undefined || lineData.maxY < pageNum) lineData.maxY = pageNum;
                })
                data.push(lineData);
            })
            return data;
            
        }
    }

    return (
        <Theme>
            <CheatCheckingNavBar />
            <GridLayout gridTemplateColumns='repeat(4, 1fr)' gridTemplateAreas='"left right right right"' height="85vh" margin='0 0 1.5% 0' gridColumnGap="1%" gridRowGap="0">
                <CheatCheckingSelectors gridClass="left" backgroundColor="#ffffff" hasBoxShadow={true} height='85vh'/>

                {activeCaseMetaDataRedux.studentInfo.length === 0
                    ? (
                        <GriddedContainer gridClass="right" >
                            <Container backgroundColor="#ffffff" margin='0 0 1% 0' minHeight='85vh' hasBoxShadow={true} >
                                <div style={{display:"flex", justifyContent:"center", alignContent:"center", padding:"39vh 2vw 39vh 2vw"}}>
                                    No cases found by this settings.
                                    <br/>
                                    If not applying any filter, and still no cases found, please contact sail stuff to run quiz data pipeline for this quiz.
                                </div>
                            </Container>
                        </GriddedContainer>)
                    : (
                        <GriddedContainer gridClass="right">
                            <Container backgroundColor="#ffffff" margin='0 0 1% 0' hasBoxShadow={true}>
                                <ContainerHeader>
                                    <ContainerTitle>Students' Info</ContainerTitle>
                                </ContainerHeader>
                                {/* {!studentInfoStatusRedux ? (studentInfoErrorRedux === "" ? (<LoadingLogo />) : (
                                    <div>Error: {studentInfoErrorRedux}</div>
                                )) : ( */}
                                    <StudentInfoTable />
                                {/* )} */}
                            </Container>

                            <Container backgroundColor="#ffffff" height='70vh' minHeight='700px' margin='0 0 1% 0' hasBoxShadow={true}>
                                <ContainerHeader>
                                    <ContainerTitle>Page View Analysis</ContainerTitle>
                                </ContainerHeader>
                                {!pageViewAnalysisStatusRedux ? (pageViewAnalysisErrorRedux === "" ? (<LoadingLogo />) : (
                                    <div>Error: {pageViewAnalysisErrorRedux}</div>
                                )) : (
                                    <>
                                        {navbarActiveCaseTypeRedux.value === 'pageview'
                                            ? (
                                                <>
                                                    <PageViewAnalysisTable />
                                                    <ContainerHeader>
                                                        <ContainerTitle>Page View Timeline</ContainerTitle>
                                                    </ContainerHeader>
                                                </>)
                                            : undefined}
                                        <PageViewGraph data={pageViewData()} />
                                    </>
                                )}

                            </Container>

                            <Container backgroundColor="#ffffff" hasBoxShadow={true} position="relative">
                                <ContainerHeader>
                                    <ContainerTitle>Answer Analysis</ContainerTitle>
                                </ContainerHeader>
                                {!answerAnalysisMapStatusRedux ? (answerAnalysisMapErrorRedux === "" ? (<LoadingLogo />) : (
                                    <div>Error: {answerAnalysisMapErrorRedux}</div>
                                )) : (
                                    <AnswerTable />
                                )}
                            </Container>
                            
                        </GriddedContainer>
                    )}
            </GridLayout>
        </Theme>
    );
};

export default Quizzes;
