import {
  navbarActiveProject,
  navbarActiveStudent,
  navbarProjects,
  navbarStudents,
  updateActiveProject,
  updateActiveStudent,
} from '../app/navbarSlice';
import {
  navbarActiveQuiz,
  navbarQuizzes,
  updateActiveQuiz,
} from '../app/navbarQuizCheatCheckingSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import Select from 'react-select';
import styled from 'styled-components';
import { theme } from '../sharedStyles/Theme';

const NavBarSelect = (props: { hide?: boolean; type: string }) => {
  const reduxDispatch = useAppDispatch();
  const project = useAppSelector(navbarActiveProject);
  const projects = useAppSelector(navbarProjects);
  const student = useAppSelector(navbarActiveStudent);
  const students = useAppSelector(navbarStudents);
  const quiz = useAppSelector(navbarActiveQuiz);
  const quizzes = useAppSelector(navbarQuizzes);

  let selected: any;
  let options = [];

  switch (props.type) {
    case 'Project':
      selected = project;
      options = projects;
      break;
    case 'Student':
      selected = student;
      options = students;
      break;
    case 'Quiz':
      selected = quiz;
      options = quizzes;
  }

  const handleSelect = (event: any) => {
    switch (props.type) {
      case 'Project':
        reduxDispatch(updateActiveProject(event));
        break;
      case 'Student':
        reduxDispatch(updateActiveStudent(event));
        break;
      case 'Quiz':
        reduxDispatch(updateActiveQuiz(event));
        break;
    }
  };

  const changeSelectWidth = () => {
    const charCount = selected.label?.length || 12;
    const width = charCount < 12 ? 10 : 2 * Math.pow(charCount, 0.69);

    return `${width}rem`;
  };

  const customStyles = {
    option: (styles: any, { isFocused }: any) => {
      return {
        ...styles,
        backgroundColor: isFocused ? theme.colors.textBackground : null,
        color: 'black',
      };
    },
    control: (provided: any, state: any) => ({
      ...provided,
      width: selected.label ? changeSelectWidth() : 500,
      backgroundColor: 'white',
      boxShadow: state.isFocused ? 0 : 0,

      border: state.isFocused
        ? `2px solid ${theme.colors.brandDark}`
        : `2px solid transparent`,
      '&:hover': {
        border: state.isFocused
          ? `2px solid ${theme.colors.brandDark}`
          : `2px solid lightgrey`,
      },
      color: 'rgba(19, 44, 62, 0.75)',
      marginLeft: '1rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: () => ({
      color: 'rgba(19, 44, 62, 0.75)',
      paddingLeft: '.5rem',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: 'rgba(19, 44, 62, 0.75)',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 99 }),
  };
  return (
    <StyledNavBarSelect hide={props.hide}>
      {props.type}
      <Select
        options={options}
        styles={customStyles}
        menuPlacement="auto"
        menuPosition="fixed"
        isSearchable={true}
        menuPortalTarget={document.body}
        onChange={(event: any) => handleSelect(event)}
        value={{
          value: selected.value,
          label: selected.label,
        }}
      />
      {props.type === 'Student' && (
        <StyledEmail href={`mailto:${student?.value}`}>
          {student.value}
        </StyledEmail>
      )}
    </StyledNavBarSelect>
  );
};

export default NavBarSelect;

const StyledNavBarSelect = styled.div<{ hide: any }>`
  color: rgba(19, 44, 62, 0.75);
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  padding-left: 2rem;

  @media (max-width: ${(props) => props.theme.responsive.md}) {
    padding-left: 0rem;
    padding-top: 0.5rem;
  }
`;

const StyledEmail = styled.a`
  color: rgba(19, 44, 62, 0.75);
  padding: 0 0.5rem;

  @media (max-width: ${(props) => props.theme.responsive.md}) {
    padding-left: 0.5rem;
  }
`;
