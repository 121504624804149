import { Link, useLocation } from 'react-router-dom';
import { StyledNavItem, StyledNavItems, navLinkProps } from '../NavBar';

import NavBarSelect from '../NavBarSelect';
import Theme from '../../sharedStyles/Theme';
import {
  navbarActiveCourse
} from '../../app/navbarSlice';
import styled from 'styled-components';
import { useAppSelector } from '../../app/hooks';

const CheatCheckingNavBar = () => {

  const location = useLocation();
  const courseValue = useAppSelector(navbarActiveCourse).value;

  const cheatCheckingNavButtons: navLinkProps[] = [
    {
      label: 'Per Quiz',
      path: "/quizzes",
      redirect: `/${courseValue}/cheatchecking/quizzes`,
    },
    {
      label: 'Student Comparison',
      path: '/comparisons',
      redirect: `/${courseValue}/cheatchecking/comparisons`,
    },
  ];
  return (
    <Theme>
      <>
        <StyledProjectsHeader>
          <StyledProjectsNavBar>
            {cheatCheckingNavButtons.map((button: navLinkProps) => (
              <StyledProjectItem
                key={button.path}
                active={location.pathname.includes(button.path)}
              >
                <Link
                  to={{
                    pathname: button.redirect,
                  }}
                >
                  {button.label}
                </Link>
              </StyledProjectItem>
            ))}
          </StyledProjectsNavBar>
          <NavBarSelect type="Quiz" />
        </StyledProjectsHeader>
      </>
    </Theme>
  );
};

export default CheatCheckingNavBar;

const StyledProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid rgba(159, 160, 162, 0.3);
  margin: 1rem 0;
`;

const StyledProjectsNavBar = styled(StyledNavItems)`
  justify-content: left;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    display: flex;
  }
`;

const StyledProjectItem = styled(StyledNavItem)`
  margin-left: 0;
  padding-bottom: 0.5rem;
`;



